

export enum HOTKEYS {
	ESCAPE = 'esc',
	CTRL_ENTER = 'ctrl+enter',
	ENTER = 'enter',
	DELETE = 'del',
	ARROW_UP = 'up',
	ARROW_DOWN = 'down',
}

export enum SANDBOXES {
	DELIVERYBK = 'DELIVERYBK',
	DELIVEROO = 'DELIVEROO',
	JUSTEAT = 'JUSTEAT',
	UBEREATS = 'UBEREATS',
}

// This is NOT a complete list, just the most used ones are listed here
export enum QueryVariableNames {
	/**
	 * Not really sure what the Stomp is, but it is used to get "peerInfo" about other devices in restarant.
	 * But it seems that it is also used in the same way as DesktopBackend (shit), so it may overwrite its value
	 */
	Stomp = 'stomp',
	/**
	 * Location of BO Local 3 (probably)
	 */
	BackOffice = 'bo',
	/**
	 * Location of DesktopBackend - the new NestJS miniserver providing WebSocket communication between devices
	 */
	DesktoBackend = 'be',
	/**
	 * URL of ClerkMaster
	 */
	Clerk = 'clerk',
	/**
	 * ID of ORBp device
	 */
	Orb = 'orb',
	/**
	 * URL of CSI SalesServer (sort of payment gateway for BK)
	 */
	Csi = 'csi',
	/**
	 * Used in bkCash. Indicates DriveMode is ON. (value can be whatever, it the variable is there, then it is ON)
	 */
	Drive = 'drive',
	/**
	 * Used in bkCash
	 */
	DriveMaster = 'driveMaster',

	/**
	 * Used in ORBs
	 */
	TimeOrderDisplay = 'timeOrderDisplay',

	/**
	 * Used in ORBs
	 */
	PushForward = 'pushForward',

	/**
	 * Used in BK-BO-Global3
	 */
	GapUrl = 'gap-url',

	/**
	 * SmartCOD is identified by index number, same way as ORBp, KIOSK or POS.
	 */
	SMART_COD_ID = 'smart-cod-id',

	MASTER_DEVICE = 'master-device',

	DUAL_SCREEN = 'dual-screen',

	ONLY_MEDIA = 'only-media',
}

/** Media target from bigData */
export enum MEDIA_TARGET {
	KIOSK_WAITING_VIDEO_SCREEN = 200,
	BURGER_MYSTERE_TOP = 230,
	BURGER_MYSTERE_BOTTOM = 231,
	DEMO_BURGER_TOP = 232,
	DEMO_BURGER_BOTTOM = 233,
	LOCAL_MAILINGS_TOP = 234,
	LOCAL_MAILINGS_BOTTOM = 235,
	KINGDOM_COUPON = 236,
	QUICK_PROMOS_POPUP_BON_PLAN = 246,
	QUICK_PROMOS_POPUP_PROMOS = 247,
	QUICK_PROMOS_POPUP_NO = 248,
	KIOSK_DISABLED = 250,
}

export enum FIDELITY_PRIVILEGES {
	CLASSIC_PRIVILEGE = 'CLASSIC_PRIVILEGE',
	CONVERTIBLE_POINTS_PRIVILEGE = 'CONVERTIBLE_POINTS_PRIVILEGE'
}

export enum PERMISSIONS {
	READ = "read-only",
	WRITE = "read-write",
	APPLY = "apply",
	DEFINE = "define",
	ALL = "all",
	MANAGEMENT = "management",
	ADMIN = "admin",
}

export enum RAP_PERMISSION_OPERATIONS {
	DSS_MANAGEMENT = "dss-management",
	PMS_MANAGEMENT = "pms-management",
	PRICE_MANAGEMENT = "price-management",
	REPORTING = "reporting",
}

export enum PASSWORD_STRENGTH {
	NONE = 0,
	WEAK = 1,
	MEDIUM = 2,
	STRONG = 3,
	SECURE = 4,
}

export enum PERMISSION_OPERATIONS {
	PRICE_MANAGEMENT = "price-management",
	USER_MANAGEMENT = "user-management",
	REPORTING = "reporting",
	RESTAURANT_MANAGEMENT = "restaurant-management",
	MONITORING = "monitoring",
	LEGACY_ACCESS = "legacy-access",
	SW_MANAGEMENT = "sw_management",
	DATA_UPDATE_MANAGEMENT = "data_update_management",
	WEB_ORDER_MANAGEMENT = "weborder-management",
	MEDIA_MANAGEMENT = "media-management",
	ACCOUNTING = "accounting",
}
